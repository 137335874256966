<template>
 <div>
  <b-card bg-variant="light">
    <b-form-group 
      label-cols-lg="3"
      label="Login"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Full Name:"
        label-for="nested-full-name"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-full-name"
          v-model="user.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Email Address:"
        label-for="nested-email-address"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-email-address"
          v-model="user.email"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password:"
        label-for="nested-password"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-password"
          v-model="user.password"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password Confirmation:"
        label-for="nested-password-confirmation"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-password-confirmation"
          v-model="user.password_confirmation"
        ></b-form-input>
      </b-form-group>
    </b-form-group>

    <b-button block variant="primary" @click="callApi">Add User Login</b-button>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
      user:{
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
    }
  },
  methods: {
    callApi () {
      http_v1.post (
        'signup', {
          // ...this.user
          user: {
            ...this.user
          }
        }
      ).then(() => {
        // this.$router.push( {name: 'UserIndex'})
        this.$router.go(-1)
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
}
</script>